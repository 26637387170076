<template>
  <v-card :loading="loading" :disabled="loading" class="mt-n10 moholand-shadow">
    <v-card-title>
      {{ 'ویرایش "' + title + '"' }}
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />

        <v-row align="center" justify="end" class="mb-3">
          <v-btn
            class="mx-4"
            dark
            color="success"
            type="submit"
            :loading="loading"
          >
            ویرایش تیکت
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MySnackbar from "@/components/MySnackbar";
import VFormBase from "vuetify-form-base";
import { getErrorText } from "@/constants/GlobalConstants";
import { formPostPrepare, formGetPrepare } from "@/constants/PanelFormHandler";

export default {
  components: {
    VFormBase,
    MySnackbar,
  },
  created() {
    this.getData();
  },

  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      title: "",
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("edit") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      model: {
        title: "",
        desc: "",
      },
      schema: {
        title: {
          type: "CustomInput",
          label: "عنوان تیکت",
          rules: [Constants.rules.required],
          col: 12,
        },
        desc: {
          type: "CustomInput",
          inputType: "textarea",
          label: "توضیحات",
          rules: [Constants.rules.required],
          col: 12,
        },
      },
    };
  },

  methods: {
    getData() {
      this.loading = true;
      MyAxios.get("/tickets/index/" + this.id, {
        params: {
          noPaginate: true,
          with: "parent",
        },
      })
        .then((response) => {
          console.log(response.data);
          this.title = response.data[0].title;
          formGetPrepare(response, this.model);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = formPostPrepare(this.model);
        MyAxios.post(`tickets/${this.id}/update`, formData)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "تیکت با موفقیت ویرایش شد",
              type: "success",
            });
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: getErrorText(error),
            });
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
  },
};
</script>

<style></style>
